@import '../../assets/css/styles.scss';

.homeContainer {
  width: 100%;
  background-color: #ebecee;
  border-radius: 4px;
  box-shadow: 0 0 14px 0 rgba(21, 22, 26, 0.05);
  padding: 25px 0px 25px 0px;
  margin-bottom: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  .containerPosts {
    width: 100%;
  }
  .titleHomeContainer {
    font-family: var(--fuente-primaria);
    font-weight: bolder;
    text-align: center;
    font-size: 25px;
  }
}

.list-section {
  div div {
    @include media-breakpoint-down(xs) {
      display: flex;
      justify-content: center;
    }
  }
  > .row:nth-of-type(2) {
    @include media-breakpoint-down(xs) {
      justify-content: center;
    }
  }
}

.react-multi-carousel-item {
  width: 190px !important;
  border-right: 1px solid rgba(205, 206, 207, 0.664);
}
.react-multi-carousel-list {
  width: auto !important;
}
.react-multiple-carousel__arrow {
  &--right,
  &--left {
    border: 3px solid #ffffffbf !important;
  }
}
