.margin-bottom-30 {
  margin-bottom: 30px;
}

.flex-50 {
  flex: 50%;
}

.style-font {
  font-family: sans-serif;
  font-weight: 900;
}

.width-40 {
  width: 40%;
}

.margin-left-15 {
  margin-left: 15px;
}

.height-39 {
  height: 39px;
}

.margin-top-25 {
  margin-top: 25px;
}

.width-flex {
  flex: 1;
}
